import React from 'react';
import Countdown from 'react-countdown';
import { Link } from 'gatsby';
import EventSlider from 'react-slick';
import Layout from '../../../components/Layout';
import Copyright from '../../../components/Copyright';
import { getSeoConfig } from '../../../constants/defaultSeoProps';
import s from '../AwsLaEvent/Events.module.scss';
import ThLogo from '../AwsLaEvent/th-logo-white.svg';
import AwsLogo from '../AwsLaEvent/aws-logo.svg';
import DateIcon from '../AwsLaEvent/date-icon.svg';
import LocationIcon from '../AwsLaEvent/map-icon.svg';
import Linkedin from '../../../assets/svg/linkedin.svg';
import Git from '../../../assets/svg/git.svg';
import StadiumFirstImage from '../../../assets/events/dodger-stadium-first.jpg';
import StadiumSecondImage from '../../../assets/events/dodger-stadium-second.jpg';
import AzatImage from '../../../assets/events/azat.png';
import AmalImage from '../../../assets/events/amal.png';
import VimalImage from '../../../assets/events/vimal.png';
import SuiteDodger from '../../../assets/events/dodger-stadium-suite-inside.png';
import SuiteDodgerOutside from '../../../assets/events/dodger-stadium-suite-outside.png';
import WestfieldLogo from '../../../assets/homepage-svgs/logo-westfield.svg';
import WarnerLogo from '../../../assets/homepage-svgs/logo-warner-bros.svg';
import DisneyLogo from '../../../assets/homepage-svgs/logo-disney.svg';
import HuluLogo from '../../../assets/homepage-svgs/logo-hulu.svg';
import TherabodyLogo from '../../../assets/homepage-svgs/logo-therabody.svg';
import UscLogo from '../../../assets/homepage-svgs/logo-usc.svg';
import EventImageRight from '../../../assets/events/dodger-stadium-full.png';

const Completionist = () => <div className={s.countdown}>You are good to go!</div>;

const addLeadingZero = (value) => {
  return value < 10 ? `0${value}` : value;
};

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <Completionist />;
  }
  return (
    <div className={s.countdown}>
      <div className={s.count}>
        <h2>{addLeadingZero(days)}</h2>
        <h3>days</h3>
      </div>
      <span>:</span>
      <div className={s.count}>
        <h2>{addLeadingZero(hours)}</h2>
        <h3>hours</h3>
      </div>
      <span>:</span>
      <div className={s.count}>
        <h2>{addLeadingZero(minutes)}</h2>
        <h3>mins</h3>
      </div>
      <span>:</span>
      <div className={s.count}>
        <h2>{addLeadingZero(seconds)}</h2>
        <h3>secs</h3>
      </div>
    </div>
  );
};

const seoParams = {
  ogImagePath: '/og-images/vip-networking-marketing.png',
  ogTitle:
    'Exclusive Invitation for You: VIP Networking and Baseball for Marketing Industry Leaders | Tech Holding',
  ogDesc:
    'We know you’re a recognized leader in the Marketing and Advertising industry, so we’re thrilled to invite you to an exclusive VIP networking event at the Dodgers game.',
  ogPath: '/events/2024-dodgers-vip-networking-marketing-industry',
};

const updatedSeo = getSeoConfig(seoParams);

// Takes UTC date and returns counter accordingly
const getUtcDate = (year, month, date, hours = 0, minutes = 0, seconds = 0) => {
  const utcDate = new Date(Date.UTC(year, month - 1, date, hours, minutes, seconds));
  return utcDate;
};

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
};

const MarketingIndustryLeaders = () => (
  <Layout
    currentPage="/events/2024-dodgers-vip-networking-marketing-industry"
    seo={updatedSeo}
    title={updatedSeo.openGraph.title}
  >
    <div className={s.eventPage}>
      <div className={s.sliderWrapper} data-aos="fade-up" data-aos-delay="200">
        <div className={s.fullSlider}>
          <EventSlider {...settings}>
            <div className={s.slideImage}>
              <img src={StadiumFirstImage} alt="" />
            </div>
            <div className={s.slideImage}>
              <img src={StadiumSecondImage} alt="" />
            </div>
          </EventSlider>
        </div>
        <div className={s.hero}>
          <div className={s.container}>
            <div className={s.heroLogo}>
              <ThLogo />
              <span className={s.logoLine} />
              <AwsLogo />
            </div>
            <div className={s.eventSchedule}>
              <div className={s.flexSchedule}>
                <h1>
                  Exclusive Invitation for You: VIP Networking and Baseball for Marketing Industry
                  Leaders
                </h1>
                <Countdown date={getUtcDate(2024, 9, 12, 2, 10, 0)} renderer={renderer} />
              </div>
              <div className={s.flex}>
                <div className={s.iconText}>
                  <DateIcon />
                  <span>Wednesday, September 11, 2024 at 7:10 pm</span>
                </div>
                <div className={s.iconText}>
                  <LocationIcon />
                  <span>
                    <strong>
                      Dodger Stadium, (Suite behind the Dodgers Dugout)
                      <br />
                      <span>1000 Vin Scully Ave, Los Angeles, CA 90012</span>
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div className={s.registerButton}>
              <a href="#register">Register</a>
            </div>
          </div>
        </div>
      </div>

      <div className={s.eventMain} data-aos="fade-up" data-aos-delay="400">
        <div className={s.container}>
          <div className={s.eventContent}>
            <div className={s.eventMainContent}>
              <p>
                We know you&apos;re a recognized leader in the Marketing and Advertising industry,
                so we&apos;re thrilled to invite you to an
                <b> exclusive VIP networking event </b>
                at the Dodgers game.
              </p>
              <p>
                Over the past decade, we&apos;ve had the pleasure of working with incredible
                companies such as Warner Brothers, Disney, Marvel, and Hulu and many more. Now,
                it&apos;s time for us to give back. We&apos;re bringing together LA Marketing and
                Advertising leaders for a special night where
                <b> you are the star.</b>
              </p>
              <div className={s.companiesSection}>
                <h3>Trusted by Leading Companies</h3>
                <div className={s.flex}>
                  <WestfieldLogo />
                  <WarnerLogo />
                  <DisneyLogo />
                  <HuluLogo />
                  <TherabodyLogo />
                  <UscLogo />
                </div>
              </div>
              <p>
                Picture yourself unwinding in a luxurious suite at Dodger Stadium (all costs
                covered), right behind the Dodgers Dugout, as they send the Cubs packing back to
                Chicago. But it&apos;s not just about the game.
              </p>
              <p>As the dust settles in between home runs, this is your chance to:</p>
              <ul>
                <li>
                  <b>Mix and Mingle: </b>
                  Rub shoulders with top industry leaders, swap stories, and discover exciting new
                  opportunities. 🥂
                </li>
                <li>
                  <b>Stay in the Know: </b>
                  Get the inside scoop on the latest tech trends and innovations that are shaking up
                  the industry. 🤖
                </li>
                <li>
                  <b>Hit a home run for your business: </b>
                  Walk away with clever insights and strategies to fast-track your growth and
                  streamline your operations. 📊
                </li>
              </ul>
              <p>
                Just keep in mind—space in our suite is limited. Secure your exclusive spot and RSVP
                now to ensure you don&apos;t miss this unforgettable night of networking and
                baseball.
              </p>
            </div>

            <div className={s.eventImage}>
              <img src={SuiteDodger} alt="" className={s.img} />
            </div>

            <div className={s.faqSection}>
              <h3>FAQ:</h3>
              <h4>Who will be there?</h4>
              <p>
                This event is exclusively for recognized leaders in the Marketing and Advertising
                industry who have been invited by Tech Holding. It’s a unique opportunity to network
                with experts from AWS and leading companies such as Warner Brothers, Disney, Marvel
                and Hulu.
              </p>
              <h4>What is included in the VIP experience?</h4>
              <p>
                Your VIP experience includes access to a luxurious suite at Dodger Stadium, located
                right behind the Dodgers Dugout. All costs for food and beverages are covered,
                ensuring a relaxing and memorable experience.
              </p>
              <h4>How do I secure my spot?</h4>
              <p>
                To secure your spot, simply RSVP above! Space is limited so be sure to confirm your
                attendance as soon as possible.
              </p>
              <h4>Can I bring a guest?</h4>
              <p>
                Due to the limited space in our suite, the invitation is personable and
                non-transferable. If you do have a colleague who would greatly benefit from
                attending, please reach out to Ana at&nbsp;
                <a href="mailto:ana.miroslavic@techholding.co">ana.miroslavic@techholding.co</a>
                &nbsp;and we’ll do our best to accommodate.
              </p>
              <h4>Is parking included?</h4>
              <p>
                Yes, complimentary parking is included with your VIP invitation. Information about
                parking arrangements and access to Dodger Stadium will be provided in your
                confirmation email.
              </p>
            </div>

            <div className={s.speakersSection}>
              <h3>Meet Your Hosts</h3>
              <div className={s.hostsSection}>
                <div className={s.card}>
                  <div className={s.speakersImg}>
                    <img src={AzatImage} alt="" />
                  </div>
                  <div className={s.speakersInfo}>
                    <div className={s.speakersTitle}>
                      <h3>Azat Aslanyan</h3>
                      <h4>
                        Co-Founder & CEO,
                        <br />
                        Tech Holding
                      </h4>
                    </div>
                    <div className={s.iconLinks}>
                      <Link
                        className={s.link}
                        to={'https://www.linkedin.com/in/azataslanyan/'}
                        target="_blank"
                      >
                        <Linkedin />
                      </Link>
                      <Link className={s.link} to={'https://github.com/AAslanyan'} target="_blank">
                        <Git />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className={s.card}>
                  <div className={s.speakersImg}>
                    <img src={AmalImage} alt="" />
                  </div>
                  <div className={s.speakersInfo}>
                    <div className={s.speakersTitle}>
                      <h3>Amal De Silva</h3>
                      <h4>
                        Senior Account Manager,
                        <br />
                        AWS
                      </h4>
                    </div>

                    <div className={s.iconLinks}>
                      <Link
                        className={s.link}
                        to={'https://www.linkedin.com/in/amal-de-silva-45310/'}
                        target="_blank"
                      >
                        <Linkedin />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className={s.card}>
                  <div className={s.speakersImg}>
                    <img src={VimalImage} alt="" />
                  </div>
                  <div className={s.speakersInfo}>
                    <div className={s.speakersTitle}>
                      <h3>Vimal Savalia</h3>
                      <h4>
                        President & Co-Founder,
                        <br />
                        Tech Holding
                      </h4>
                    </div>
                    <div className={s.iconLinks}>
                      <Link
                        className={s.link}
                        to={'https://www.linkedin.com/in/vimalsavalia/'}
                        target="_blank"
                      >
                        <Linkedin />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={s.eventImage}>
              <img src={SuiteDodgerOutside} alt="" className={s.img} />
            </div>
          </div>

          <div className={s.space} id="register">
            Register
          </div>

          <div className={s.eventForm}>
            <div className={s.eventImageRight}>
              <img src={EventImageRight} alt="" className={s.img} />
            </div>
            <div className={s.registerForm}>
              <div className={s.eventClosedInfo}>
                <p>Thank you for your overwhelming interest in our event!</p>
                <p>
                  We have reached full capacity and are unable to accept additional attendees at
                  this time.
                </p>
                <p>
                  If you would like to be placed on a waitlist or need further assistance, please
                  contact Ana directly at&nbsp;
                  <a href="mailto:ana.miroslavic@techholding.co">ana.miroslavic@techholding.co</a>
                </p>
                <p>We appreciate your understanding and hope to see you at our next event!</p>
              </div>
              <div
                className={s.eventClosed}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: `<div id="mc_embed_shell" class="register-form disabled-form">
                <div id="mc_embed_signup">
                   <form action="https://techholding.us18.list-manage.com/subscribe/post?u=12eacc3d78ee8cee150f4a09c&amp;id=6d2ac1e5cd&amp;f_id=004f16e7f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
                      <div id="mc_embed_signup_scroll">
                         <h3>Register Now</h3>
                         <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
                         <div class="mc-field-group">
                            <input type="text" name="FNAME" class="form-input required text" id="mce-FNAME" required="" placeholder="Enter First Name" value="">
                            <label class="form-label" for="mce-FNAME">First Name <span class="asterisk">*</span></label>
                        </div>
                         <div class="mc-field-group">
                            <input type="text" name="LNAME" class="form-input required text" id="mce-LNAME" required="" placeholder="Enter Last Name" value="">
                            <label class="form-label" for="mce-LNAME">Last Name <span class="asterisk">*</span></label>
                        </div>
                         
                         <div class="mc-field-group">
                            <input type="email" name="EMAIL" class="form-input required email" id="mce-EMAIL" required="" placeholder="Enter Work Email" value="">
                            <label class="form-label" for="mce-EMAIL">Work Email <span class="asterisk">*</span></label>
                        </div>
                         <div class="mc-field-group">
                            <input type="text" name="PHONE" class="form-input REQ_CSS" id="mce-PHONE" placeholder="Enter Phone Number" value="">
                            <label class="form-label" for="mce-PHONE">Phone Number </label>
                        </div>
                        <div class="mc-field-group">
                            <input type="text" name="COMPNAME" class="form-input text" id="mce-COMPNAME" required="" placeholder="Enter Company Name" value="">
                            <label class="form-label" for="mce-COMPNAME">Company Name <span class="asterisk">*</span></label>
                        </div>
                        <div hidden=""><input type="hidden" name="tags" value="2104156"></div>
                        <div id="mce-responses" class="clear">
                            <div class="response" id="mce-error-response" style="display: none;"></div>
                            <div class="response" id="mce-success-response" style="display: none;"></div>
                        </div>
                        <div aria-hidden="true" style="position: absolute; left: -5000px;">
                            <input type="text" name="b_12eacc3d78ee8cee150f4a09c_6d2ac1e5cd" tabindex="-1" value="">
                        </div>
                         <div class="optionalParent">
                            <div class="clear foot">
                               <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Register">
                            </div>
                         </div>
                      </div>
                   </form>
                </div>
                <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script><script type="text/javascript">(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[0]='EMAIL';ftypes[0]='email';fnames[4]='PHONE';ftypes[4]='phone';fnames[6]='COMPNAME';ftypes[6]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[5]='BIRTHDAY';ftypes[5]='birthday';fnames[7]='MEETINGTYP';ftypes[7]='radio';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
             </div>`,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={s.homeFooter}>
        <footer className={s.root}>
          <div className={s.footerTop}>
            <div className={s.container}>
              <div className={s.footerRow}>
                <div className={s.footerMenu}>
                  <h5>Company</h5>

                  <ul className={s.footerMenuList}>
                    <li className={s.footerMenuItem}>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li className={s.footerMenuItem}>
                      <Link to="/services">What We Do</Link>
                    </li>
                    <li className={s.footerMenuItem}>
                      <Link to="/work">Case Studies</Link>
                    </li>
                    <li className={s.footerMenuItem}>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li className={s.footerMenuItem}>
                      <Link to="/careers">Careers</Link>
                    </li>
                    <li className={s.footerMenuItem}>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={s.footerLine} />
              <div className={s.copyrightContainer}>
                <Copyright />
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </Layout>
);

export default MarketingIndustryLeaders;
